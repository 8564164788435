<template>
  <base-layout-two :page-title="`Care Plan`" :page-default-back-link="`/users/${$route.params.id}`">
    <section class="ion-padding background-white">
      <h1>New Care Plan</h1>
    </section>

    <div class="padding-8">
      <v-form @submit="onSubmit">
        <ion-grid class="ion-no-padding grid-padding">
          <ion-row>
            <ion-col size="12">
              <base-card title="Date">
                <base-input label-text="Care Plan Date *">
                  <v-field name="date" v-slot="{ field }" v-model="care_plan.date" :rules="required">
                    <ion-input name="date" v-bind="field" placeholder="" type="date"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="date" class="error-message"></v-error-message>
              </base-card>
            </ion-col>
          </ion-row>

          <!-- Pain Management -->
          <ion-row>
            <ion-col>
              <base-card title="Pain Management">
                <ion-grid>
                  <ion-row>
                    <!-- Pain Level -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Pain Level">
                        <v-field name="pain_level" v-slot="{ field }" v-model="care_plan.pain_level">
                          <select v-bind="field" class="regular-select">
                            <option :value="1">1 - Lowest</option>
                            <option :value="2">2</option>
                            <option :value="3">3</option>
                            <option :value="4">4</option>
                            <option :value="5">5</option>
                            <option :value="6">6</option>
                            <option :value="7">7</option>
                            <option :value="8">8</option>
                            <option :value="9">9</option>
                            <option :value="10">10 - Highest</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="pain_level" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- Pain Location -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Pain Location">
                        <v-field name="pain_location" v-slot="{ field }" v-model="care_plan.pain_location">
                          <ion-input name="pain_location" v-bind="field" placeholder="" type="text" autocapitalize
                            autocorrect></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="pain_location" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- Perineal Cold Packs -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Perineal Cold Pack">
                        <v-field name="perineal_cold_pack" v-slot="{ field }" v-model="care_plan.perineal_cold_pack">
                          <select v-bind="field" class="regular-select">
                            <option :value="1">Yes</option>
                            <option :value="0">No</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="perineal_cold_pack" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- Maternal Bleeding -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Maternal Bleeding?">
                        <v-field name="maternal_bleeding" v-slot="{ field }" v-model="care_plan.maternal_bleeding">
                          <select v-bind="field" class="regular-select">
                            <option value="none">None</option>
                            <option value="light">Light</option>
                            <option value="medium">Medium</option>
                            <option value="heavy">Heavy</option>
                            <option value="clots">Clots</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="maternal_bleeding" class="error-message"></v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>
          </ion-row>

          <!-- Physical Recovery -->
          <ion-row>
            <ion-col>
              <base-card title="Physical Recovery">
                <ion-grid>
                  <ion-row>
                    <!-- Sitz Bath -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Sitz Bath?">
                        <v-field name="sitz_bath" v-slot="{ field }" v-model="care_plan.sitz_bath">
                          <select v-bind="field" class="regular-select">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="maybe">Maybe later</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="sitz_bath" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- Foot Bath -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Foot Bath?">
                        <v-field name="foot" v-slot="{ field }" v-model="care_plan.foot_bath">
                          <select v-bind="field" class="regular-select">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="maybe">Maybe later</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="foot_bath" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- Lactation Assistance -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Lactation Assistance?">
                        <v-field name="lactation_assistance" v-slot="{ field }" v-model="care_plan.lactation_assistance">
                          <select v-bind="field" class="regular-select">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="maybe">Maybe later</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="lactation_assistance" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- Postnatal Massage -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Postnatal Massage?">
                        <v-field name="postnatal_massage" v-slot="{ field }" v-model="care_plan.postnatal_massage">
                          <select v-bind="field" class="regular-select">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="maybe">Maybe later</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="postnatal_massage" class="error-message"></v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>
          </ion-row>

          <!-- Breast Pumping & Feeding -->
          <ion-row>
            <ion-col>
              <base-card title="Breast Pumping & Feeding">
                <ion-grid>
                  <ion-row>
                    <!-- Breast Feeding -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Breastfeeding?">
                        <v-field name="breast_feeding" v-slot="{ field }" v-model="care_plan.breast_feeding">
                          <select v-bind="field" class="regular-select">
                            <option :value="1">Yes</option>
                            <option :value="0">No</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="breast_feeding" class="error-message"></v-error-message>
                    </ion-col>

                    <!-- Pumping -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Pumping?">
                        <v-field name="pumping" v-slot="{ field }" v-model="care_plan.pumping">
                          <select v-bind="field" class="regular-select">
                            <option :value="1">Yes</option>
                            <option :value="0">No</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="pumping" class="error-message"></v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <base-card title="Education & Guidance">
                <!-- General Notes -->
                <base-input label-text="Notes">
                  <v-field name="education" v-slot="{ field }" v-model="care_plan.education">
                    <ion-textarea :auto-grow="true" rows="1" v-bind="field" placeholder="" autocapitalize autocorrect>
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="education" class="error-message"></v-error-message>
              </base-card>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <base-card title="Mental Wellness">
                <!-- Mental Wellness -->
                <base-input label-text="Notes">
                  <v-field name="mental_wellness" v-slot="{ field }" v-model="care_plan.mental_wellness">
                    <ion-textarea :auto-grow="true" rows="1" v-bind="field" placeholder="" autocapitalize autocorrect>
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="mental_wellness" class="error-message"></v-error-message>
              </base-card>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <base-card title="General Notes">
                <!-- General Notes -->
                <base-input label-text="Notes">
                  <v-field name="general_notes" v-slot="{ field }" v-model="care_plan.general_notes">
                    <ion-textarea :auto-grow="true" rows="1" v-bind="field" placeholder="" autocapitalize autocorrect>
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="general_notes" class="error-message"></v-error-message>
              </base-card>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button expand="block" type="submit" class="margin-y-16">
          Save
        </ion-button>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { format } from "date-fns";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      user: {},
      care_plan: {
        mother_id: this.$route.params.id,
        date: format(new Date(), "yyyy-MM-dd"),
      },
      required: yup.string().required(),
    };
  },

  ionViewWillEnter() { },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/care_plans`,
        data: this.care_plan,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.care_plan = response.data.care_plan;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Care Plan Created", color: "secondary" });

          this.$router.back();
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>